import React, { useEffect } from "react";
import "./App.css";
import MobileApp from "./components/MobileApp";
import { useTranslation } from "react-i18next";
import "./fonts/KidsDaddy.ttf";
import "./fonts/KidsDaddy.otf";
import { useNavigate } from "react-router-dom";
import ProfessionalIcon from "./components/ProfessionalIcon";

function Home() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const personalApps = [
    {
      name: "Toss it",
      slug: "toss-it",
      color: "#9DBFFF",
      onClick: () =>
        window.open(
          "https://apps.apple.com/us/app/toss-it/id1562160948",
          "_blank"
        ),
    },
    {
      name: "Janken",
      slug: "janken",
      color: "#FFB886",
      onClick: () =>
        window.open(
          "https://apps.apple.com/us/app/janken/id1562490628",
          "_blank"
        ),
    },
    {
      name: "Airstar",
      slug: "airstar",
      color: "#5272CA",
      onClick: () =>
        window.open(
          "https://apps.apple.com/us/app/airstar/id1642031895",
          "_blank"
        ),
    },
    {
      name: "1000 km/h",
      slug: "1000kmh",
      color: "#222222",
      onClick: () =>
        window.open(
          "https://apps.apple.com/us/app/1000-km-h/id6503222996",
          "_blank"
        ),
    },
  ];

  const professionalApps = [
    {
      name: "GAC Technology",
      slug: "gac-technology",
      color: "#FFFFFF",
      onClick: () => navigate("/resume", { state: { tab: 0 } }),
      stack: "React Native / PHP",
      location: "Lyon, France",
      link: "https://www.gac-technology.com",
      stackLink: "https://reactnative.dev",
      locationLink: "https://www.google.com/search?q=Lyon%2C+France",
    },
    {
      name: "USMB",
      slug: "usmb",
      color: "#FFFFFF",
      onClick: () => navigate("/resume", { state: { tab: 1 } }),
      stack: "React.JS",
      location: "Chambéry, France",
      link: "https://www.univ-smb.fr",
      stackLink: "https://react.dev",
      locationLink: "https://www.google.com/search?q=Chamb%C3%A9ry%2C+France",
    },
    {
      name: "BNC",
      slug: "bnc",
      color: "#FFFFFF",
      onClick: () => navigate("/resume", { state: { tab: 2 } }),
      stack: "React Native",
      location: "Montréal, Canada",
      link: "https://www.bnc.ca",
      stackLink: "https://reactnative.dev",
      locationLink: "https://www.google.com/search?q=Montr%C3%A9al%2C+Canada",
    },
    {
      name: "Decathlon",
      slug: "d4",
      color: "#FFFFFF",
      onClick: () => navigate("/resume", { state: { tab: 3 } }),
      stack: "React.JS / Java",
      location: "Lille, France",
      link: "https://www.decathlon.com",
      stackLink: "https://react.dev",
      locationLink: "https://www.google.com/search?q=Lille%2C+France",
    },
  ];

  /**
   * Preload "avatar" poster
   */
  useEffect(() => {
    const img = new Image();
    img.src = "fishing.jpg";
  }, []);

  return (
    <div className="app">
      <div className="profile-container">
        <a
          className="profile"
          href="https://www.linkedin.com/in/ykedin"
          target="_blank"
          rel="noreferrer"
        >
          <img className="profile" src="me.png" alt="Me" />
        </a>
        <div className="arrow-container">
          <svg
            className="arrow"
            fill="currentColor"
            width="700pt"
            height="700pt"
            version="1.1"
            viewBox="0 0 700 700"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g>
              <path d="m270.45 297.12c17.738-2.8203 64.871-10.34 75.211-58.516 6.0664-28.23-8.1523-50.004-25.363-56.91-23.703-9.5195-43.375-9.0078-58.414 1.4531-25.121 17.516-27.855 56.859-27.258 78.75 0.15234 5.4023 0.71875 10.766 1.3008 16.133l0.58203 5.6211c-9.0898 0.32422-18.305 0.12109-27.344-0.10156l-6.6289-0.13672c-20.234-0.39453-40.453-1.4531-60.652-2.5117-9.0586-0.48047-18.113-0.95703-27.172-1.3672-4.8047-0.59766-8.9219 3.5039-9.1445 8.3242-0.22266 4.8359 3.5039 8.9219 8.3242 9.1445 9.0234 0.42578 18.047 0.90625 27.07 1.3672 20.406 1.0781 40.809 2.1523 61.234 2.5469l6.5781 0.13672c5.8438 0.13672 11.773 0.27344 17.723 0.27344 4.8828 0 9.7773-0.23828 14.664-0.49609 3.8398 8.5781 9.7812 16.07 18.316 22.113 21.141 14.973 55.148 20.219 92.422 20.219 60.293 0 129.08-13.723 165.62-22.148 4.7148-1.0938 7.6406-5.793 6.5625-10.492-1.0938-4.6992-5.7422-7.6211-10.492-6.5625-44.363 10.219-193.01 40.809-244.01 4.6992-4.1133-2.9141-7.2734-6.2422-9.7656-9.8945 1.8516-0.30469 3.7383-0.38281 5.5781-0.76953 1.3164-0.27734 3.043-0.55078 5.0586-0.875zm-16.492-14.902-0.63281-6.0664c-0.53125-4.9062-1.0586-9.8086-1.1953-14.73-0.87109-31.258 6.3398-54.551 19.773-63.918 4.8359-3.3672 10.699-5.0586 17.5-5.0586 7.1094 0 15.246 1.8281 24.387 5.4844 8.082 3.2461 19.227 16.168 14.75 37-7.8086 36.453-43.629 42.16-60.84 44.91-2.3594 0.375-4.3594 0.69922-5.8945 1.0273-2.582 0.54687-5.1992 0.99219-7.8477 1.3516z" />
              <path d="m543.92 374.61c-0.95703 4.7344 2.1211 9.3477 6.8711 10.289 0.5625 0.12109 1.1445 0.17188 1.7266 0.17188 4.0859 0 7.7227-2.8711 8.5625-7.043 1.2656-6.2734 12.73-36.301 29.941-78.375 4.2891-10.477 4.582-19.703 0.82031-27.43-3.793-7.8281-11.434-13.434-22.711-16.645-20.68-5.8945-41.871-10.887-62.359-15.707-8.6484-2.0352-17.293-4.0664-25.926-6.168-4.6133-1.1797-9.418 1.7422-10.562 6.4258-1.1445 4.6836 1.7266 9.418 6.4258 10.562 8.6641 2.1211 17.363 4.168 26.043 6.2227 20.301 4.7695 41.305 9.707 61.59 15.484 4.3086 1.2305 9.8438 3.5391 11.758 7.4844 1.8477 3.793 0.32422 9.2305-1.2812 13.141-10.664 26.062-28.848 71.297-30.898 81.586z" />
            </g>
          </svg>
          <span className="me-text">{t("home.me")}</span>
        </div>
      </div>
      <div className="terminal title">
        <div className="bar">
          <div className="btn"></div>
        </div>
        <div className="body title">
          <span className="name typewriter">
            yanou.<span className="name-dev">dev</span>()
          </span>
        </div>
      </div>
      <div className="apps-container">
        <span
          className="apps-title small typewriter"
          style={{
            width: "26ch",
            animation: "typing 1.25s steps(26)",
          }}
        >
          {t("home.projects")}.
          <span className="name-dev">{t("home.professional")}</span>();
        </span>
        <div className="apps">
          {professionalApps.map((item, index) => (
            <ProfessionalIcon
              style={{
                animationDelay: `${index * 80}ms`,
                zIndex: professionalApps.length - index,
              }}
              key={item.name}
              name={item.name}
              stack={item.stack}
              location={item.location}
              slug={item.slug}
              color={item.color}
              link={item.link}
              stackLink={item.stackLink}
              locationLink={item.locationLink}
              noVideo={item.noVideo}
              onClick={item.onClick}
            />
          ))}
        </div>
      </div>
      <div className="apps-container">
        <span
          className="apps-title small typewriter"
          style={{
            width: "22ch",
            animation: "typing 1.25s steps(22)",
          }}
        >
          {t("home.projects")}.
          <span className="name-dev">{t("home.personal")}</span>();
        </span>
        <div className="apps">
          {personalApps.map((item, index) => (
            <MobileApp
              style={{
                animationDelay: `${
                  professionalApps.length * 80 + index * 80
                }ms`,
              }}
              key={item.name}
              name={item.name}
              slug={item.slug}
              color={item.color}
              noVideo={item.noVideo}
              onClick={item.onClick}
            />
          ))}
        </div>
      </div>
      <div
        onMouseUp={() =>
          window.open(
            "https://www.paypal.com/donate/?hosted_button_id=3QHEEAKR6R5XA",
            "_blank"
          )
        }
        className="button"
      >
        <div className="inner-button">{t("home.coffee")}</div>
      </div>
      <div className="footer">
        <a href="mailto:yanis.kerriou@gmail.com">{t("home.contact")}</a>
        <span>{` ${"\u2022"} `}</span>
        <a href="https://x.com/yanou_dev">@yanou_dev</a>
      </div>
    </div>
  );
}

export default Home;
